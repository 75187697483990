import { Container } from "typedi"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { School } from "../../../lib/eszgsz-api/model/School"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { CreateSchoolPayload } from "../../../lib/eszgsz-api/payload/school/CreateSchoolPayload"
import { EditSchoolPayload } from "../../../lib/eszgsz-api/payload/school/EditSchoolPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "school", namespaced: true })
export class SchoolModule extends VuexModule {
    public schools: School[] = []

    public get getById(): (id: number) => School | undefined {
        return (id: number): School | undefined => this.schools.find((school) => school.id === id)
    }

    @Mutation
    public storeSchools(schools: School[]): void {
        this.schools = schools
    }

    @Mutation
    public storeSchool(school: School): void {
        const index = this.schools.findIndex(({ id }) => school.id === id)

        this.schools = [
            ...(index !== -1
                ? this.schools.slice(0, index).concat(this.schools.slice(index + 1))
                : this.schools),
            school,
        ]
    }

    @Action({ rawError: true })
    public async loadPaginated(payload?: PaginatedPayload): Promise<Pagination<School>> {
        const response = await Container.get(EszgszApi).paginateSchools(payload)

        this.storeSchools(response.items)

        return response
    }

    @Action({ rawError: true })
    public async create(payload: CreateSchoolPayload): Promise<School> {
        return await Container.get(EszgszApi).createSchool(payload)
    }

    @Action({ rawError: true })
    public async load({ id }: Pick<School, "id">): Promise<School> {
        const school = await Container.get(EszgszApi).loadSchool(id)

        this.storeSchool(school)

        return school
    }

    @Action({ rawError: true })
    public async edit({ id, ...payload }: Pick<School, "id"> & EditSchoolPayload): Promise<School> {
        return await Container.get(EszgszApi).editSchool(id, payload)
    }

    @Action({ rawError: true })
    public async remove({ id }: Pick<School, "id">): Promise<void> {
        await Container.get(EszgszApi).removeSchool(id)
    }

    @Action({ rawError: true })
    public async searchByName(
        { name, ...options }: { name: string; betweenCustomWorkdays?: boolean },
    ): Promise<Array<Pick<School, "id" | "name">>> {
        return await Container.get(EszgszApi).searchSchoolsByName(name, options)
    }
}

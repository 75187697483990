import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { Document } from "../../../lib/eszgsz-api/model/Document"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { DocumentUploadPayload } from "../../../lib/eszgsz-api/payload/document/DocumentUploadPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "document", namespaced: true })
export class DocumentModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginated(payload?: PaginatedPayload): Promise<Pagination<Document>> {
        return await Container.get(EszgszApi).paginateDocuments(payload)
    }

    @Action({ rawError: true })
    public async upload(payload: DocumentUploadPayload): Promise<Document> {
        return await Container.get(EszgszApi).uploadDocument(payload)
    }

    @Action({ rawError: true })
    public async download(payload: { id: number }): Promise<{ filename: string; content: Blob }> {
        return await Container.get(EszgszApi).downloadDocument(payload.id)
    }

    @Action({ rawError: true })
    public async remove({ id }: { id: number }): Promise<void> {
        await Container.get(EszgszApi).removeDocument(id)
    }
}

import jsonwebtoken from "jsonwebtoken"
import { Container } from "typedi"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { LoginPayload } from "../../../lib/eszgsz-api/payload/LoginPayload"

export interface JwtTokenData {
    iat: number
    nbf: number
    exp: number
    aud: string[]
    iss: string
    sub: string

    userId: number
    name: string
    username: string
    email: string
}

@Module({ name: "auth", namespaced: true })
export class AuthModule extends VuexModule {
    public jwtToken: string | null = null

    public jwtTokenData: JwtTokenData | null = null

    public roles: Array<{ name: string; permissions: string[]; meta?: Record<string, unknown> }> = []

    public permissions: string[] = []

    public availableRoles: Array<{ id: string; role: string; permissions: string[]; meta?: Record<string, unknown> }> = []

    public selectedRole: { id: string; role: string; permissions: string[]; meta?: Record<string, unknown> } | null = null

    public tokenRefreshError: boolean = false

    public get tokenExpireAt(): number | null {
        return this.jwtTokenData ? this.jwtTokenData.exp : null
    }

    @Mutation
    public storeJwtToken(
        { jwtToken, roles, permissions }: { jwtToken: string | null; roles: Array<{ name: string; permissions: string[]; meta?: Record<string, unknown> }>; permissions: string[] },
    ): void {
        this.jwtToken = jwtToken
        this.jwtTokenData = jwtToken !== null
            ? jsonwebtoken.decode(jwtToken, { complete: false }) as JwtTokenData
            : null
        this.roles = roles
        this.permissions = permissions

        this.availableRoles = roles.reduce<Array<{ id: string; role: string; permissions: string[]; meta?: Record<string, unknown> }>>((
            acc,
            role,
        ) => {
            if (role.name === "urn:eszgsz:role:school") {
                (role.meta as { schools: Array<{ id: number; name: string }> }).schools.forEach((school) => {
                    acc.push({
                        id: `${role.name}:${school.id}`,
                        role: role.name,
                        permissions: role.permissions,
                        meta: school,
                    })
                })
            } else if (role.name === "urn:eszgsz:role:supplier") {
                (role.meta as { suppliers: Array<{ id: number; name: string }> }).suppliers.forEach((supplier) => {
                    acc.push({
                        id: `${role.name}:${supplier.id}`,
                        role: role.name,
                        permissions: role.permissions,
                        meta: supplier,
                    })
                })
            } else {
                acc.push({ id: role.name, role: role.name, permissions: role.permissions })
            }

            return acc
        }, [])

        if (this.availableRoles.length === 0) {
            this.selectedRole = null
        } else if (this.availableRoles.length === 1) {
            this.selectedRole = this.availableRoles[0]
        }

        this.tokenRefreshError = false
    }

    @Mutation
    public setTokenRefreshError(): void {
        this.tokenRefreshError = true
    }

    @Mutation
    public selectRole(id: string): void {
        this.selectedRole = this.availableRoles.find((role) => role.id === id) || null
    }

    @Action({ rawError: true })
    public async login(payload: LoginPayload): Promise<void> {
        const { jwtToken, roles, permissions } = await Container.get(EszgszApi).login(payload)

        this.storeJwtToken({ jwtToken, roles, permissions })
    }

    @Action({ rawError: true })
    public async refresh(): Promise<void> {
        try {
            const { jwtToken, roles, permissions } = await Container.get(EszgszApi).refreshToken()

            this.storeJwtToken({ jwtToken, roles, permissions })
        } catch (error) {
            this.setTokenRefreshError()

            throw error
        }
    }

    @Action({ rawError: true })
    public logout(): void {
        this.storeJwtToken({ jwtToken: null, roles: [], permissions: [] })
    }
}

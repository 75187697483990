export const lang = {
    page: {
        title: "Kézikönyv",

        toc: {
            title: "Tartalomjegyzék",
        },

        changelog: {
            title: "Fejlesztési napló",
        },
        introduction: {
            title: "Bevezetés",
        },
        users: {
            title: "Felhasználók kezelése",
        },
    },
}

import { Container } from "typedi"
import { Store } from "vuex"
import createPersistedState from "vuex-persistedstate"
import { ActivityLogModule } from "./modules/ActivityLogModule"
import { AuthModule } from "./modules/AuthModule"
import { ConversationModule } from "./modules/ConversationModule"
import { DocumentModule } from "./modules/DocumentModule"
import { EmployerModule } from "./modules/EmployerModule"
import { FoodOrderModule } from "./modules/FoodOrderModule"
import { MealModule } from "./modules/MealModule"
import { MenuModule } from "./modules/MenuModule"
import { PersonWithSpecialNeedsModule } from "./modules/PersonWithSpecialNeedsModule"
import { SchoolModule } from "./modules/SchoolModule"
import { SideboardKitchenModule } from "./modules/SideboardKitchenModule"
import { SnackbarModule } from "./modules/SnackbarModule"
import { SupplierModule } from "./modules/SupplierModule"
import { SupplyItemModule } from "./modules/SupplyItemModule"
import { UserModule } from "./modules/UserModule"
import { WorkdayModule } from "./modules/WorkdayModule"
import { WorkingTimeRecordModule } from "./modules/WorkingTimeRecordModule"

declare global {
    interface Window {
        Cypress: unknown
        store: Store<unknown>
    }
}

export function createStore(): Store<unknown> {
    const store = new Store<unknown>({
        strict: true,
        modules: {
            "activity-log": ActivityLogModule,
            auth: AuthModule,
            conversation: ConversationModule,
            document: DocumentModule,
            employer: EmployerModule,
            "food-order": FoodOrderModule,
            meal: MealModule,
            menu: MenuModule,
            "person-with-special-needs": PersonWithSpecialNeedsModule,
            school: SchoolModule,
            "sideboard-kitchen": SideboardKitchenModule,
            snackbar: SnackbarModule,
            supplier: SupplierModule,
            "supply-item": SupplyItemModule,
            user: UserModule,
            workday: WorkdayModule,
            "working-time-record": WorkingTimeRecordModule,
        },
        plugins: [
            createPersistedState({
                storage: window.localStorage,
                paths: [
                    "auth.jwtToken",
                    "auth.jwtTokenData",
                    "auth.roles",
                    "auth.permissions",
                    "auth.availableRoles",
                    "auth.selectedRole",
                ],
            }),
        ],
    })

    Container.set(Store, store)

    if (window.Cypress) {
        // Add `store` to the window object only when testing with Cypress
        window.store = store
    }

    return store
}

import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { User } from "../../../lib/eszgsz-api/model/User"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { CreateUserPayload } from "../../../lib/eszgsz-api/payload/CreateUserPayload"
import { EditUserPayload } from "../../../lib/eszgsz-api/payload/EditUserPayload"
import { EditUserProfilePayload } from "../../../lib/eszgsz-api/payload/EditUserProfilePayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "user", namespaced: true })
export class UserModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginated(payload?: PaginatedPayload): Promise<Pagination<User>> {
        return await Container.get(EszgszApi).paginateUsers(payload)
    }

    @Action({ rawError: true })
    public async create(payload: CreateUserPayload): Promise<User> {
        return await Container.get(EszgszApi).createUser(payload)
    }

    @Action({ rawError: true })
    public async edit({ id, ...payload }: Pick<User, "id"> & EditUserPayload): Promise<User> {
        return await Container.get(EszgszApi).editUser(id, payload)
    }

    @Action({ rawError: true })
    public async editProfile(payload: EditUserProfilePayload): Promise<User> {
        return await Container.get(EszgszApi).editUserProfile(payload)
    }

    @Action({ rawError: true })
    public async remove({ id }: Pick<User, "id">): Promise<void> {
        await Container.get(EszgszApi).removeUser(id)
    }

    @Action({ rawError: true })
    public async searchByName({ name }: { name: string }): Promise<Array<Pick<User, "id" | "name">>> {
        return await Container.get(EszgszApi).searchUsersByName(name)
    }
}

import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { Menu } from "../../../lib/eszgsz-api/model/Menu"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { CreateMenuPayload } from "../../../lib/eszgsz-api/payload/CreateMenuPayload"
import { EditMenuPayload } from "../../../lib/eszgsz-api/payload/EditMenuPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "menu", namespaced: true })
export class MenuModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginated(payload?: PaginatedPayload): Promise<Pagination<Menu>> {
        return await Container.get(EszgszApi).paginatedMenus(payload)
    }

    @Action({ rawError: true })
    public async create(payload: CreateMenuPayload): Promise<Menu> {
        return await Container.get(EszgszApi).createMenu(payload)
    }

    @Action({ rawError: true })
    public async edit({ id, ...payload }: Pick<Menu, "id"> & EditMenuPayload): Promise<Menu> {
        return await Container.get(EszgszApi).editMenu(id, payload)
    }

    @Action({ rawError: true })
    public async remove({ id }: Pick<Menu, "id">): Promise<void> {
        await Container.get(EszgszApi).removeMenu(id)
    }

    @Action({ rawError: true })
    public async searchByName(
        { name, special }: { name: string; special?: boolean },
    ): Promise<Array<Pick<Menu, "id" | "name">>> {
        return await Container.get(EszgszApi).searchMenusByName(name, { special })
    }
}

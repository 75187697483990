import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { Employer } from "../../../lib/eszgsz-api/model/Employer"
import { WorkingTimeRecord } from "../../../lib/eszgsz-api/model/WorkingTimeRecord"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { CreateWorkingTimeRecordPayload } from "../../../lib/eszgsz-api/payload/working-time-record/CreateWorkingTimeRecordPayload"
import { EditWorkingTimeRecordPayload } from "../../../lib/eszgsz-api/payload/working-time-record/EditWorkingTimeRecordPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "working-time-record", namespaced: true })
export class WorkingTimeRecordModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginated(
        payload?: PaginatedPayload,
    ): Promise<Pagination<Pick<Employer, "id" | "name"> & { correct_days: number; problematic_days: number; holiday_count: number }>> {
        return await Container.get(EszgszApi).loadPaginatedWorkingTimeRecords(payload)
    }

    @Action({ rawError: true })
    public async loadPaginatedForEmployer(
        { employerId, ...payload }: { employerId: number } & PaginatedPayload,
    ): Promise<Pagination<WorkingTimeRecord>> {
        return await Container.get(EszgszApi).loadPaginatedWorkingTimeRecordsForEmployer(employerId, payload)
    }

    @Action({ rawError: true })
    public async create(
        { employerId, ...payload }: { employerId: number } & CreateWorkingTimeRecordPayload,
    ): Promise<WorkingTimeRecord> {
        return await Container.get(EszgszApi).createWorkingTimeRecordForEmployer(employerId, payload)
    }

    @Action({ rawError: true })
    public async edit(
        { employerId, id, ...payload }: { employerId: number; id: number } & EditWorkingTimeRecordPayload,
    ): Promise<WorkingTimeRecord> {
        return await Container.get(EszgszApi).editWorkingTimeRecordForEmployer(employerId, id, payload)
    }

    @Action({ rawError: true })
    public async remove(
        { employerId, id }: { employerId: number; id: number },
    ): Promise<void> {
        await Container.get(EszgszApi).removeWorkingTimeRecord(employerId, id)
    }

    @Action({ rawError: true })
    public async logStart(payload: { code: string }): Promise<void> {
        return await Container.get(EszgszApi).logStartWorkingTimeRecord(payload)
    }

    @Action({ rawError: true })
    public async logFinish(payload: { code: string }): Promise<void> {
        return await Container.get(EszgszApi).logFinishWorkingTimeRecord(payload)
    }
}

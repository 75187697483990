import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { PersonWithSpecialNeeds } from "../../../lib/eszgsz-api/model/PersonWithSpecialNeeds"
import { School } from "../../../lib/eszgsz-api/model/School"
import { WeekOrder } from "../../../lib/eszgsz-api/model/WeekOrder"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { EditWeekOrderPayload } from "../../../lib/eszgsz-api/payload/food-orders/EditWeekOrderPayload"
import { SummaryForOfficePayload } from "../../../lib/eszgsz-api/payload/food-orders/SummaryForOfficePayload"
import { SummaryForSchoolPayload } from "../../../lib/eszgsz-api/payload/food-orders/SummaryForSchoolPayload"
import { SummaryForSupplierPayload } from "../../../lib/eszgsz-api/payload/food-orders/SummaryForSupplierPayload"
import { WeekOrderPayload } from "../../../lib/eszgsz-api/payload/food-orders/WeekOrderPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "food-order", namespaced: true })
export class FoodOrderModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginatedSummaryForOffice(payload: PaginatedPayload<SummaryForOfficePayload>): Promise<Pagination<unknown>> {
        return await Container.get(EszgszApi).loadPaginatedFoodOrdersSummaryForOffice(payload)
    }

    @Action({ rawError: true })
    public async loadPaginatedSummaryForSchool(payload: PaginatedPayload<SummaryForSchoolPayload>): Promise<Pagination<unknown>> {
        return await Container.get(EszgszApi).loadPaginatedFoodOrdersSummaryForSchool(payload)
    }

    @Action({ rawError: true })
    public async loadPaginatedSummaryForSupplier(payload: PaginatedPayload<SummaryForSupplierPayload>): Promise<Pagination<unknown>> {
        return await Container.get(EszgszApi).loadPaginatedFoodOrdersSummaryForSupplier(payload)
    }

    @Action({ rawError: true })
    public async loadPaginatedDailySummaryForSupplier(
        payload: PaginatedPayload,
    ): Promise<Pagination<unknown> & { schools: School[] }> {
        return await Container.get(EszgszApi).loadPaginatedFoodOrdersDailySummaryForSupplier(payload)
    }

    @Action({ rawError: true })
    public async loadPaginatedDailySummaryForSideboardKitchen(
        payload: PaginatedPayload,
    ): Promise<Pagination<unknown> & { personsWithSpecialNeeds: PersonWithSpecialNeeds[] }> {
        return await Container.get(EszgszApi).loadFoodOrdersDailySummaryForSideboardKitchen(payload)
    }

    @Action({ rawError: true })
    public async loadWeekly(
        payload: WeekOrderPayload,
    ): Promise<{ days: Array<"monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday">; orders: WeekOrder[] }> {
        return await Container.get(EszgszApi).loadFoodOrdersWeekly(payload)
    }

    @Action({ rawError: true })
    public async editWeekly(
        payload: EditWeekOrderPayload,
    ): Promise<{ days: Array<"monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday">; orders: WeekOrder[] }> {
        return await Container.get(EszgszApi).editFoodOrdersWeekly(payload)
    }
}

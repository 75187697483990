export const lang = {
    name: "Felhasználó | Felhasználók",

    "removed-user": "Törölt felhasználó",

    model: {
        name: "Név",
        username: "Felhasználónév",
        password: "Jelszó",
        "password-confirmation": "Jelszó megerősítése",
        email: "E-mail cím",
        roles: "Jogosultságok",
        "role-option": {
            admin: "Adminisztrátor",
            office: "Iroda",
            "labor-department": "Munkaügyi osztály",
            "technical-department": "Műszaki osztály",
        },
    },

    navigation: {
        list: "Felhasználók",
    },

    btn: {
        create: {
            tooltip: "Új felhasználó",
        },
    },

    page: {
        list: {
            title: "Felhasználók kezelése",
        },
    },

    dialog: {
        create: {
            title: "Új felhasználó létrehozása",
        },
        edit: {
            title: "Felhasználó szerkesztése",
        },
        remove: {
            title: "Felhasználó törlése",
            content: "Biztosan törli a kiválasztott felhasználót?",
        },
    },

    snackbar: {
        create: {
            success: "Az új felhasználó sikeresen létrehozva.",
        },
        edit: {
            success: "Felhasználó sikeresen szerkesztve.",
        },
        remove: {
            success: "Felhasználó sikeresen törölve.",
        },
    },
}

import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { Workday } from "../../../lib/eszgsz-api/model/Workday"
import { EditWorkdaysPayload } from "../../../lib/eszgsz-api/payload/workdays/EditWorkdaysPayload"
import { ListWorkdaysPayload } from "../../../lib/eszgsz-api/payload/workdays/ListWorkdaysPayload"

@Module({ name: "workday", namespaced: true })
export class WorkdayModule extends VuexModule {
    @Action({ rawError: true })
    public async load(payload: ListWorkdaysPayload): Promise<Workday[]> {
        return await Container.get(EszgszApi).loadWorkdays(payload)
    }

    @Action({ rawError: true })
    public async edit(payload: EditWorkdaysPayload): Promise<Workday[]> {
        return await Container.get(EszgszApi).editWorkdays(payload)
    }
}

export const lang = {
    model: {
        level: "Típus",
        date: "Dátum",
        token: "Esemény",
        user: "Felhasználó",
        payload: "Adatok",
        "level-option": {
            info: "Általános információt tartalmazó naplóbejegyzés.",
            warning: "Figyelmet igénylő naplóbejegyzés.",
            error: "Rendszerhiba.",
        },
        "token-option": {
            "auth:authentication-failed": "A(z) <strong>{username}</strong> felhasználóval belépési kísérlet történt.",
            "auth:authenticated": "A(z) <strong>{username}</strong> felhasználó sikeresen belépett.",

            "conversation:create": "Beszélgetés létrehozva.",
            "conversation:reply": "Válasz küldése.",

            "employer:create": "A(z) <strong>{employer.name}</strong> dolgozó létrehozva.",
            "employer:edit": "A(z) <strong>{employer.name}</strong> dolgozó szerkesztve.",
            "employer:remove": "A(z) <strong>{employer.name}</strong> dolgozó törölve.",

            "food-order:edit-weekly": "<strong>{year}</strong>. <strong>{week}</strong>. heti étel megrendelés szerkesztve.",

            "meal:create": "A(z) <strong>{meal.name}</strong> étkezés létrehozva.",
            "meal:edit": "A(z) <strong>{meal.name}</strong> étkezés szerkesztve.",
            "meal:remove": "A(z) <strong>{meal.name}</strong> étkezés törölve.",

            "menu:create": "A(z) <strong>{menu.name}</strong> menü létrehozva.",
            "menu:edit": "A(z) <strong>{menu.name}</strong> menü szerkesztve.",
            "menu:remove": "A(z) <strong>{menu.name}</strong> menü törölve.",

            "school:create": "A(z) <strong>{school.name}</strong> iskola létrehozva.",
            "school:edit": "A(z) <strong>{school.name}</strong> iskola szerkesztve.",
            "school:remove": "A(z) <strong>{school.name}</strong> iskola törölve.",

            "sideboard-kitchen:create": "A(z) <strong>{sideboardKitchen.name}</strong> tálaló konyha létrehozva.",
            "sideboard-kitchen:edit": "A(z) <strong>{sideboardKitchen.name}</strong> tálaló konyha szerkesztve.",
            "sideboard-kitchen:remove": "A(z) <strong>{sideboardKitchen.name}</strong> tálaló konyha törölve.",

            "supplier:create": "A(z) <strong>{supplier.name}</strong> beszállító létrehozva.",
            "supplier:edit": "A(z) <strong>{supplier.name}</strong> beszállító szerkesztve.",
            "supplier:remove": "A(z) <strong>{supplier.name}</strong> beszállító törölve.",

            "supply-item:create": "A(z) <strong>{supplyItem.name}</strong> konyha eszköz létrehozva.",
            "supply-item:edit": "A(z) <strong>{supplyItem.name}</strong> konyha eszköz szerkesztve.",
            "supply-item:remove": "A(z) <strong>{supplyItem.name}</strong> konyha eszköz törölve.",

            "user:create": "A(z) <strong>{user.name}</strong> felhasználó létrehozva.",
            "user:edit": "A(z) <strong>{user.name}</strong> felhasználó szerkesztve.",
            "user:edit-profile": "A(z) <strong>{user.name}</strong> felhasználói profilja szerkesztve.",
            "user:remove": "A(z) <strong>{user.name}</strong> felhasználó törölve.",

            "workday:edit": "Munkanapok szerkesztve",

            "working-time-record:create": "Munkaidő bejegyzés létrehozva.",
            "working-time-record:edit": "Munkaidő bejegyzés szerkesztve.",
            "working-time-record:remove": "Munkaidő bejegyzés törölve.",
            "working-time-record:log:start": "Munka kezdése.",
            "working-time-record:log:finish": "Munka befejezése.",
        },
    },

    navigation: {
        list: "Tevékenységi napló",
    },

    page: {
        list: {
            title: "Tevékenységi napló",
        },
    },

    dialog: {
        details: {
            title: "Naplóbejegyzés részletei",
        },
    },
}

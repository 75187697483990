import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { Meal } from "../../../lib/eszgsz-api/model/Meal"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { CreateMealPayload } from "../../../lib/eszgsz-api/payload/CreateMealPayload"
import { EditMealPayload } from "../../../lib/eszgsz-api/payload/EditMealPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "meal", namespaced: true })
export class MealModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginated(payload?: PaginatedPayload): Promise<Pagination<Meal>> {
        return await Container.get(EszgszApi).paginateMeals(payload)
    }

    @Action({ rawError: true })
    public async create(payload: CreateMealPayload): Promise<Meal> {
        return await Container.get(EszgszApi).createMeal(payload)
    }

    @Action({ rawError: true })
    public async edit({ id, ...payload }: Pick<Meal, "id"> & EditMealPayload): Promise<Meal> {
        return await Container.get(EszgszApi).editMeal(id, payload)
    }

    @Action({ rawError: true })
    public async remove({ id }: Pick<Meal, "id">): Promise<void> {
        await Container.get(EszgszApi).removeMeal(id)
    }

    @Action({ rawError: true })
    public async searchByName({ name }: { name: string }): Promise<Array<Pick<Meal, "id" | "name">>> {
        return await Container.get(EszgszApi).searchMealsByName(name)
    }
}

export const lang = {
    arrayMinSize: "Minimum {min} elem kiválasztása kötelező",
    exists: "Már létezik",
    fileSizeExceeded: "A feltölteni kívánt fájl mérete meghaladja a maximálisan megengedett.",
    isConfirms: "A mezőnek egyeznie kell a {property} mezővel",
    isDateString: "A mező értéke érvénytelen dátum formátum",
    isEmail: "A mezőnek érvényes email címet kell tartalmaznia",
    isNotEmpty: "A mező megadása kötelező",
    isUnique: "A mező értéke nem egyedi",
    matches: "A mező formátuma érvénytelen",
    maxLength: "A mező értéke nem lehet hosszabb mint {max} karaktert",
    min: "A mező értéke legalább {min} kell lennie",
    minLength: "A mező értéke nem lehet rövidebb mint {min} karakter",

    unclosed: "Munkaidő már elkezdve",
    unopened: "Munkaidő nincs elkezdve",
}

export const lang = {
    name: "Menü | Menük",

    model: {
        name: "Név",
        special: "Diétás",
        "special-option": {
            diet: "Diétás",
            "no-diet": "Nem diétás",
        },
    },

    navigation: {
        list: "Menük",
    },

    btn: {
        create: {
            tooltip: "Új menü",
        },
    },

    page: {
        list: {
            title: "Menük kezelése",
        },
    },

    dialog: {
        create: {
            title: "Új menü létrehozása",
        },
        edit: {
            title: "Menük szerkesztése",
        },
        remove: {
            title: "Menü törlése",
            content: "Biztosan törli a kiválasztott menüt?",
        },
    },

    snackbar: {
        create: {
            success: "Az új menü sikeresen létrehozva.",
        },
        edit: {
            success: "Menü sikeresen szerkesztve.",
        },
        remove: {
            success: "Menü sikeresen törölve.",
        },
    },
}

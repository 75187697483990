












import Vue from "vue"
import { Component } from "vue-property-decorator"

@Component({
    name: "manual-page",
})
export default class ManualPage extends Vue {
}

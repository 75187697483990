export const lang = {
    name: "Tálaló konyha | Tálaló konyhák",

    model: {
        name: "Név",
    },

    navigation: {
        list: "Tálaló konyhák",
    },

    btn: {
        create: {
            tooltip: "Új tálaló konyha",
        },
    },

    page: {
        list: {
            title: "Tálaló konyhák kezelése",
        },
    },

    dialog: {
        create: {
            title: "Tálaló konyha létrehozása",
        },
        edit: {
            title: "Tálaló konyha szerkesztése",
        },
        remove: {
            title: "Tálaló konyha törlése",
            content: "Biztosan törli a kiválasztott tálaló konyhát?",
        },
    },

    snackbar: {
        create: {
            success: "Az új tálaló konyha sikeresen létrehozva.",
        },
        edit: {
            success: "Tálaló konyha sikeresen szerkesztve.",
        },
        remove: {
            success: "Tálaló konyha sikeresen törölve.",
        },
    },
}

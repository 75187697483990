import { i18n } from "../../src"
import { HttpException } from "./HttpException"

export interface EntityError {
    property: string
    constraints?: { [key: string]: string }
    children?: EntityError[]
}

export class UnprocessableEntityException extends HttpException {
    public readonly messages: EntityError[]

    public constructor(messages: EntityError[]) {
        super()

        this.messages = messages

        Object.setPrototypeOf(this, UnprocessableEntityException.prototype)
    }

    public getMessages(): Record<string, string[]> {
        return this.collectMessages("", this.messages)
    }

    protected collectMessages(prefix: string, messages: EntityError[]): Record<string, string[]> {
        return messages.reduce<{ [key: string]: string[] }>((acc, { property, constraints, children }) => {
            if (constraints) {
                if (!Object.prototype.hasOwnProperty.call(acc, property)) {
                    acc[`${prefix}${property}`] = []
                }

                acc[`${prefix}${property}`].push(...Object.keys(constraints || []).map((rule) => {
                    try {
                        const values = JSON.parse(constraints[rule])

                        return i18n.t(`validation.${rule}`, values) as string
                    } catch {
                        // Catch error silently

                        return i18n.t(`validation.${rule}`) as string
                    }
                }))
            }

            if (children) {
                acc = { ...acc, ...this.collectMessages(`${prefix}${property}.`, children) }
            }

            return acc
        }, {})
    }
}

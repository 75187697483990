export const lang = {
    name: "Beszállító | Beszállítók",

    model: {
        name: "Név",
    },

    navigation: {
        list: "Beszállítók",
    },

    btn: {
        create: {
            tooltip: "Új beszállító",
        },
    },

    page: {
        list: {
            title: "Beszállítások kezelése",
        },
    },

    dialog: {
        create: {
            title: "Új beszállító létrehozása",
        },
        edit: {
            title: "Beszállító szerkesztése",
        },
        remove: {
            title: "Beszállító törlése",
            content: "Biztosan törli a kiválasztott beszállítót?",
        },
    },

    snackbar: {
        create: {
            success: "Az új beszállító sikeresen létrehozva.",
        },
        edit: {
            success: "Beszállító sikeresen szerkesztve.",
        },
        remove: {
            success: "Beszállító sikeresen törölve.",
        },
    },
}

import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { Supplier } from "../../../lib/eszgsz-api/model/Supplier"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { CreateSupplierPayload } from "../../../lib/eszgsz-api/payload/CreateSupplierPayload"
import { EditSupplierPayload } from "../../../lib/eszgsz-api/payload/EditSupplierPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "supplier", namespaced: true })
export class SupplierModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginated(payload?: PaginatedPayload): Promise<Pagination<Supplier>> {
        return await Container.get(EszgszApi).paginateSuppliers(payload)
    }

    @Action({ rawError: true })
    public async create(payload: CreateSupplierPayload): Promise<Supplier> {
        return await Container.get(EszgszApi).createSupplier(payload)
    }

    @Action({ rawError: true })
    public async edit({ id, ...payload }: Pick<Supplier, "id"> & EditSupplierPayload): Promise<Supplier> {
        return await Container.get(EszgszApi).editSupplier(id, payload)
    }

    @Action({ rawError: true })
    public async remove({ id }: Pick<Supplier, "id">): Promise<void> {
        await Container.get(EszgszApi).removeSupplier(id)
    }

    @Action({ rawError: true })
    public async searchByName({ name }: { name: string }): Promise<Array<Pick<Supplier, "id" | "name">>> {
        return await Container.get(EszgszApi).searchSuppliersByName(name)
    }
}

import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { SupplyItem } from "../../../lib/eszgsz-api/model/SupplyItem"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { CreateSupplyItemPayload } from "../../../lib/eszgsz-api/payload/supply-item/CreateSupplyItemPayload"
import { EditSupplyItemPayload } from "../../../lib/eszgsz-api/payload/supply-item/EditSupplyItemPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "supply-item", namespaced: true })
export class SupplyItemModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginated(payload?: PaginatedPayload): Promise<Pagination<SupplyItem>> {
        return await Container.get(EszgszApi).paginateSupplyItems(payload)
    }

    @Action({ rawError: true })
    public async create(payload: CreateSupplyItemPayload): Promise<SupplyItem> {
        return await Container.get(EszgszApi).createSupplyItem(payload)
    }

    @Action({ rawError: true })
    public async edit({ id, ...payload }: Pick<SupplyItem, "id"> & EditSupplyItemPayload): Promise<SupplyItem> {
        return await Container.get(EszgszApi).editSupplyItem(id, payload)
    }

    @Action({ rawError: true })
    public async remove({ id }: Pick<SupplyItem, "id">): Promise<void> {
        await Container.get(EszgszApi).removeSupplyItem(id)
    }

    @Action({ rawError: true })
    public async searchByName({ name }: { name: string }): Promise<Array<Pick<SupplyItem, "id" | "name" | "packing">>> {
        return await Container.get(EszgszApi).searchSupplyItemByName(name)
    }
}

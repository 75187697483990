import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { Log } from "../../../lib/eszgsz-api/model/Log"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "activity-log", namespaced: true })
export class ActivityLogModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginated(payload?: PaginatedPayload): Promise<Pagination<Log>> {
        return await Container.get(EszgszApi).paginateActivityLogs(payload)
    }
}

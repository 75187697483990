export const lang = {
    model: {
        "workday-type": {
            workday: "Munkanap",
            holiday: "Munkaszüneti nap",
        },
    },

    navigation: {
        edit: "Munka(szüneti) napok",
    },

    page: {
        edit: {
            title: "Munka(szüneti) napok",
        },
    },

    btn: {
        previous: {
            tooltip: "Előző hónap",
        },
        next: {
            tooltip: "Következő hónap",
        },
    },
    snackbar: {
        edit: {
            success: "Munka(szüneti) napok sikeresen elmentve.",
        },
    },
}

export const lang = {
    name: "Iskola | Iskolák",

    model: {
        name: "Név",
        "persons-with-special-needs": "Személyek",
        "custom-workdays": "Egyedi munkaszüneti napok",
    },

    navigation: {
        list: "Iskolák",
    },

    btn: {
        create: {
            tooltip: "Új iskola",
        },
    },

    page: {
        list: {
            title: "Iskolák kezelése",
        },
        create: {
            title: "Iskola létrehozása",
        },
        edit: {
            title: "Iskola szerkesztése",
        },
    },

    block: {
        base: {
            title: "Alap adatok",
        },
        meal: {
            title: "Étkezések",
            empty: "Az iskolához nincs étkezés hozzárendelve.",
            btn: {
                add: {
                    tooltip: "Új étkezés hozzáadása",
                },
            },
            dialog: {
                add: {
                    title: "Étkezés hozzáadása",
                },
                edit: {
                    title: "Étkezés szerkesztése",
                },
                remove: {
                    title: "Étkezés törlése",
                    content: "Biztosan törli a kiválasztott étkezést?",
                },
            },
        },
        "person-with-special-needs": {
            title: "Speciális étrendet igénylő személyek",
            empty: "Az iskolához nincs speciális étrendet igénylő személy hozzárendelve.",
            btn: {
                add: {
                    tooltip: "Új személy hozzáadása",
                },
            },
            dialog: {
                add: {
                    title: "Személy hozzáadása",
                },
                edit: {
                    title: "Személy szerkesztése",
                },
                remove: {
                    title: "Személy törlése",
                    content: "Biztosan törli a kiválasztott személyt?",
                },
            },
        },
    },

    dialog: {
        remove: {
            title: "Iskola törlése",
            content: "Biztosan törli a kiválasztott iskolát?",
        },
    },

    snackbar: {
        create: {
            success: "Az új iskola sikeresen létrehozva.",
        },
        edit: {
            success: "Iskola sikeresen szerkesztve.",
        },
        remove: {
            success: "Iskola sikeresen törölve.",
        },
    },
}

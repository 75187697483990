export const lang = {
    content: "Dobja ide a fájljait, majd kezdje meg a feltöltést.",
    restriction: "Fájlonként maximum 10 MB fájl tölthető fel.",
    empty: "Nincs fájl kiválasztva.",
    "file-count": "{count} fájl feltöltésre kiválasztva.",

    btn: {
        select: {
            content: "Fájlok böngészése",
        },
    },
}

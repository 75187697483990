export const lang = {
    navigation: {
        weekly: "Heti étel rendelés",
        summary: "Étel rendelés összesítő",
        "daily-summary": "Napi étel rendelés",
    },

    page: {
        weekly: {
            title: "{year}, {week}. heti étel rendelés",
            breadcrumb: "Heti étel rendelés",

            alert: {
                "empty-school": "Nincs iskola kiválasztva. A megrendelések megjelenítéséhez először ki kell választani egy iskolát.",
            },

            field: {
                school: {
                    label: "Iskola kiválasztása",
                },
            },

            btn: {
                previous: {
                    tooltip: "Előző hét",
                },
                next: {
                    tooltip: "Következő hét",
                },
                "select-week": {
                    content: "{start} - {finish}",
                    tooltip: "Hét választó",
                },
                "people-with-special-needs": {
                    tooltip: "Speciális étrendet igénylő személyek megadása",
                },
                "edit-closed-days": {
                    content: "Lezárt napok szerkesztése",
                },
            },

            dialog: {
                "people-with-special-needs": {
                    title: "Speciális étrendet igénylő személyek",
                },
            },

            snackbar: {
                save: {
                    success: "Heti megrendelés sikeresen elmentve.",
                },
            },
        },
        summary: {
            title: "Étel rendelés összesítő",

            btn: {
                "select-date": {
                    content: "{date}",
                    tooltip: "Nap kiválasztása",
                },
            },
        },
        "daily-summary": {
            title: "Napi étel rendelés",

            titleWithDate: "{date} napi étel rendelés",

            btn: {
                "select-date": {
                    content: "{date}",
                    tooltip: "Nap kiválasztása",
                },
                previous: {
                    tooltip: "Előző nap",
                },
                next: {
                    tooltip: "Következő nap",
                },
            },
        },
    },

    block: {
        "people-with-special-needs": {
            title: "Speciális étrendet igénylő személyek",
        },
    },

    export: {
        summary: {
            title: "{start} - {end} közti étel rendelés összesítő",
        },
    },
}

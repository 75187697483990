export const lang = {
    title: "ÉSzGSz belépés",
    btn: {
        login: {
            content: "Belépés",
        },
        passwordReminder: {
            content: "Jelszó emlékeztető",
        },
    },
    validation: {
        unauthorized: "A felhasználónév jelszó és páros hibás",
    },
    footer: {
        title: "ÉSzGSz",
        subtitle: "Étkeztetési Szolgáltató Gazdasági Szervezet",
    },
}

export const lang = {
    name: "Konyha eszköz | Konyha eszközök",

    model: {
        name: "Név",
        packing: "Kiszerelés",
    },

    navigation: {
        list: "Konyha eszközök",
    },

    btn: {
        create: {
            tooltip: "Új konyha eszköz",
        },
    },

    page: {
        list: {
            title: "Konyha eszközök kezelése",
        },
    },

    dialog: {
        create: {
            title: "Konyha eszköz létrehozása",
        },
        edit: {
            title: "Konyha eszköz szerkesztése",
        },
        remove: {
            title: "Konyha eszköz törlése",
            content: "Biztosan törli a kiválasztott konyha eszközt?",
        },
    },

    snackbar: {
        create: {
            success: "Az új konyha eszköz sikeresen létrehozva.",
        },
        edit: {
            success: "Konyha eszköz sikeresen szerkesztve.",
        },
        remove: {
            success: "Konyha eszköz sikeresen törölve.",
        },
    },
}

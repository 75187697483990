export const lang = {
    navigation: {
        list: "Dokumentumok",
    },

    model: {
        name: "Név",
        size: "Méret",
        file: "Fájl",
        "uploaded-at": "Feltöltve",
    },

    btn: {
        upload: {
            tooltip: "Fájlok feltöltése",
        },
        download: {
            tooltip: "Letöltés",
        },
        "start-upload": {
            content: "Feltöltés",
        },
    },

    page: {
        list: {
            title: "Dokumentumok kezelése",
        },
    },

    dialog: {
        upload: {
            title: "Fájl feltöltés",
        },
        remove: {
            title: "Dokumentum törlése",
            content: "Biztosan törli a kiválasztott dokumentumot?",
        },
    },

    snackbar: {
        upload: {
            success: "Dokumentum sikeresen feltöltve.",
            partial: "Dokumentumok egy része sikeresen feltöltve.",
            fail: "Hiba történt a dokumentumok feltöltése során.",
        },
        remove: {
            success: "Dokumentum sikeresen törölve.",
        },
    },
}

export const lang = {
    model: {
        type: "Típus",
        recipient: "Címzett",
        subject: "Tárgy",
        "type-option": {
            common: "Általános",
            announcement: "Közlemény",
            order: "Megrendelés",
            "food-order-issue": "Adagszám probléma",
            "technical-request": "Műszaki segítség",
            "labor-request": "Munkaügyi kérés",
        },
    },

    navigation: {
        list: "Beszélgetések",
    },

    btn: {
        create: {
            tooltip: "Új beszélgetés indítása",
        },
        refresh: {
            tooltip: "Frissítés",
        },
        filter: {
            tooltip: "Beszélgetések szűrése",
        },
        archive: {
            tooltip: "Beszélgetés archiválása",
        },
        send: {
            content: "Küldés",
            tooltip: "Küldés",
        },
    },

    tab: {
        active: "Aktív",
        archived: "Archivált",
    },

    page: {
        list: {
            title: "Beszélgetések",

            "unread-message-count": "{count} darab olvasatlan üzenet.",
            empty: "Nincs nyitott beszélgetés.",
        },

        "list-archived": {
            title: "Archivált beszélgetések",

            empty: "Nincs archivált beszélgetés.",
        },

        create: {
            title: "Új beszélgetés",
            "supply-item": {
                btn: {
                    add: {
                        tooltip: "Új tétel",
                    },
                },

                empty: "Nincs konyha eszköz kiválasztva.",
            },
        },

        details: {
            title: "Beszélgetés részletei",

            "not-selected": "Nincs beszélgetés kiválasztva.",
        },
    },

    dialog: {
        archive: {
            title: "Beszélgetés archiválása",
            content: "Biztosan archiválja a kiválasztott beszélgetést? Az archiválás után a beszélgetéshez nem lehet új üzenetet írni.",
        },
        filter: {
            title: "Beszélgetések szűrése",
        },
        "supply-item": {
            create: {
                title: "Új tétel",
            },
            edit: {
                title: "Tétel szerkesztése",
            },
        },
    },

    snackbar: {
        create: {
            success: "ÚJ beszélgetés sikeresen megnyitva.",
        },
        archive: {
            success: "A beszélgetés sikeresen archiválva.",
        },
        reply: {
            success: "Az üzenet sikeresen elküldve.",
        },
    },
}

import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { Conversation } from "../../../lib/eszgsz-api/model/Conversation"
import { ConversationMessage } from "../../../lib/eszgsz-api/model/ConversationMessage"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { CreateConversationPayload } from "../../../lib/eszgsz-api/payload/conversation/CreateConversationPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "conversation", namespaced: true })
export class ConversationModule extends VuexModule {
    @Action({ rawError: true })
    public async getUnreadCount(): Promise<{ unreadConversationCount: number }> {
        return await Container.get(EszgszApi).getUnreadConversationCount()
    }

    @Action({ rawError: true })
    public async loadPaginatedConversations(payload?: PaginatedPayload): Promise<Pagination<Pick<Conversation, "id" | "type" | "subject"> & { lastMessageDate: string; unreadMessageCount: number }>> {
        return await Container.get(EszgszApi).loadPaginatedConversations(payload)
    }

    @Action({ rawError: true })
    public async loadPaginatedArchivedConversations(payload?: PaginatedPayload): Promise<Pagination<Pick<Conversation, "id" | "type" | "subject"> & { lastMessageDate: string; unreadMessageCount: number }>> {
        return await Container.get(EszgszApi).loadPaginatedArchivedConversations(payload)
    }

    @Action({ rawError: true })
    public async loadMessages({ conversationId }: { conversationId: number }): Promise<ConversationMessage[]> {
        return await Container.get(EszgszApi).loadConversationMessages(conversationId)
    }

    @Action({ rawError: true })
    public async loadArchivedMessages({ conversationId }: { conversationId: number }): Promise<ConversationMessage[]> {
        return await Container.get(EszgszApi).loadArchivedConversationMessages(conversationId)
    }

    @Action({ rawError: true })
    public async create(payload: CreateConversationPayload): Promise<Partial<Pick<Conversation, "messages">> & Omit<Conversation, "messages">> {
        return await Container.get(EszgszApi).createConversation(payload)
    }

    @Action({ rawError: true })
    public async load(
        { conversationId }: { conversationId: number },
    ): Promise<Conversation & { _meta: { permissions: string[] } }> {
        return await Container.get(EszgszApi).loadConversation(conversationId)
    }

    @Action({ rawError: true })
    public async loadArchived(
        { conversationId }: { conversationId: number },
    ): Promise<Conversation & { _meta: { permissions: string[] } }> {
        return await Container.get(EszgszApi).loadArchivedConversation(conversationId)
    }

    @Action({ rawError: true })
    public async reply({ conversationId, ...payload }: { conversationId: number; content: string }): Promise<ConversationMessage> {
        return await Container.get(EszgszApi).replyToConversation(conversationId, payload)
    }

    @Action({ rawError: true })
    public async archive({ conversationId }: { conversationId: number }): Promise<void> {
        await Container.get(EszgszApi).archiveConversation(conversationId)
    }

    @Action({ rawError: true })
    public async searchRecipientsByName(payload: { name: string }): Promise<Array<{ id: number; name: string; type: "supplier" | "school" | "sideboard-kitchen" | "user" }>> {
        return await Container.get(EszgszApi).searchRecipientsByName(payload)
    }
}

export const lang = {
    model: {
        date: "Dátum",
        start: "Munka kezdete",
        finish: "Munka vége",
        "correct-days": "Ledolgozott napok",
        "problematic-days": "Problémás napok",
        "holiday-count": "Távollét",
    },

    navigation: {
        list: "Munkaidő nyilvántartás",
        log: "Munkaidő regisztrálása",
    },

    btn: {
        create: {
            tooltip: "Új bejegyzés hozzáadása",
        },
    },

    page: {
        list: {
            title: "Munkaidő nyilvántartás",
        },
        details: {
            title: "{name} {start} - {end} közti munkaidő nyilvántartása",
            breadcrumb: "Részletes munkaidő nyilvántartás",
        },
        log: {
            title: "Munkaidő regisztrálása",

            btn: {
                start: {
                    content: "Kezdés",
                },
                end: {
                    content: "Befejezés",
                },
            },

            snackbar: {
                start: {
                    success: "Munkaidő megkezdve.",
                },
                end: {
                    success: "Munkaidő befejezve.",
                },
            },
        },
    },

    dialog: {
        create: {
            title: "Új bejegyzés létrehozása",
        },
        edit: {
            title: "Bejegyzés szerkesztése",
        },
        remove: {
            title: "Bejegyzés törlése",
            content: "Biztosan törli a kiválasztott bejegyzést?",
        },
    },

    snackbar: {
        create: {
            success: "Az új bejegyzés sikeresen létrehozva.",
        },
        edit: {
            success: "Bejegyzés sikeresen szerkesztve.",
        },
        remove: {
            success: "Bejegyzés sikeresen törölve.",
        },
    },

    export: {
        summary: {
            title: "{start} - {end} közti munkaidő összesítés",
        },
    },
}

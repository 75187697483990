export const lang = {
    unknown: "Ismeretlen",

    today: "Mai nap",

    block: {
        search: {
            title: "Keresés",
        },
    },

    table: {
        header: {
            action: "Műveletek",
            sum: "Összesen",
            "upload-status": "Állapot",
        },
        field: {
            search: {
                placeholder: "Keresés",
            },
        },
        btn: {
            search: {
                tooltip: "Keresés",
            },
            download: {
                tooltip: "Letöltés",
            },
        },
    },

    field: {
        combobox: {
            hint: "Új elemet az ENTER vagy a TAB billentyűkkel lehet hozzáadni.",
        },
        "interval-start": {
            label: "Időszak kezdete",
        },
        "interval-end": {
            label: "Időszak vége",
        },
        file: {
            hint: "A feltöltendő fájl mérete nem haladhatja meg a 10MB-ot.",
        },
    },

    btn: {
        yes: {
            content: "Igen",
        },
        no: {
            content: "Nem",
        },
        edit: {
            tooltip: "Szerkesztés",
        },
        remove: {
            tooltip: "Törlés",
        },
        save: {
            content: "Mentés",
        },
        cancel: {
            content: "Mégsem",
        },
        details: {
            tooltip: "Részletek",
        },
        search: {
            content: "Keresés",
        },
        close: {
            content: "Bezárás",
        },
        filter: {
            content: "Szűrés",
        },
        clearFilter: {
            content: "Feltételek törlése",
        },
    },

    days: {
        monday: {
            short: "HÉT",
            long: "Hétfő",
        },
        tuesday: {
            short: "KED",
            long: "Kedd",
        },
        wednesday: {
            short: "SZE",
            long: "Szerda",
        },
        thursday: {
            short: "CSÜ",
            long: "Csütörtök",
        },
        friday: {
            short: "PÉN",
            long: "Péntek",
        },
        saturday: {
            short: "SZO",
            long: "Szombat",
        },
        sunday: {
            short: "VAS",
            long: "Vasárnap",
        },
    },

    unit: {
        piece: {
            short: "db",
        },
    },

    snackbar: {
        snackbar: {
            auth: {
                tokenExpired: "Automatikus kilépés, lejárt munkamenet",
            },
        },
    },
}

export const lang = {
    name: "Dolgozó | Dolgozók",

    model: {
        name: "Név",
        code: "Azonosító kód",
        "work-schedule": "Munkarend",
        "work-schedule-option": {
            "4-hour": "4 órás",
            "6-hour": "6 órás",
            "8-hour": "8 órás",
        },
    },

    navigation: {
        list: "Dolgozók",
    },

    btn: {
        create: {
            tooltip: "Új dolgozó",
        },
        generate: {
            tooltip: "Azonosító generálása",
        },
    },

    page: {
        list: {
            title: "Dolgozók kezelése",
        },
    },

    dialog: {
        create: {
            title: "Új dolgozó létrehozása",
        },
        edit: {
            title: "Dolgozó szerkesztése",
        },
        remove: {
            title: "Dolgozó törlése",
            content: "Biztosan törli a kiválasztott dolgozót?",
        },
    },

    snackbar: {
        create: {
            success: "Az új dolgozó sikeresen létrehozva.",
        },
        edit: {
            success: "Dolgozó sikeresen szerkesztve.",
        },
        remove: {
            success: "Dolgozó sikeresen törölve.",
        },
    },
}

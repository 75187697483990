/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { RouteConfig } from "vue-router"

export const routes: RouteConfig[] = [
    {
        path: "/",
        redirect: "/home",
    },

    {
        path: "/",
        component: () => import(/* webpackChunkName: "layout/simple" */ "./components/layout/SimpleLayout.vue"),
        children: [
            {
                name: "login",
                path: "/login",
                component: () => import(/* webpackChunkName: "page/login" */ "./components/pages/LoginPage.vue"),
                meta: {
                    title: "layout.routes.login.title",
                    hideAppBar: true,
                    hideSidebar: true,
                },
            },
        ],
    },

    {
        path: "/",
        component: () => import(/* webpackChunkName: "layout/main" */ "./components/layout/MainLayout.vue"),
        children: [
            {
                name: "home",
                path: "/home",
                component: () => import(/* webpackChunkName: "page/home" */ "./components/pages/HomePage.vue"),
                meta: {
                    icon: "mdi-home",
                    title: "app.page.home.title",
                },
            },

            {
                name: "users",
                path: "/users",
                component: () => import(/* webpackChunkName: "page/user/list" */ "./components/pages/user/UserListPage.vue"),
                meta: {
                    icon: "mdi-account-multiple",
                    title: "user.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:user:list",
                },
            }, {
                name: "menus",
                path: "/menus",
                component: () => import(/* webpackChunkName: "page/menu/list" */ "./components/pages/menu/MenuListPage.vue"),
                meta: {
                    icon: "mdi-hamburger",
                    title: "menu.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:menu:list",
                },
            }, {
                name: "meals",
                path: "/meals",
                component: () => import(/* webpackChunkName: "page/meal/list" */ "./components/pages/meal/MealListPage.vue"),
                meta: {
                    icon: "mdi-food-fork-drink",
                    title: "meal.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:meal:list",
                },
            }, {
                name: "suppliers",
                path: "/suppliers",
                component: () => import(/* webpackChunkName: "page/supplier/list" */ "./components/pages/supplier/SupplierListPage.vue"),
                meta: {
                    icon: "mdi-truck",
                    title: "supplier.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:supplier:list",
                },
            }, {
                name: "schools",
                path: "/schools",
                component: () => import(/* webpackChunkName: "page/school/list" */ "./components/pages/school/SchoolListPage.vue"),
                meta: {
                    icon: "mdi-school",
                    title: "school.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:school:list",
                },
            }, {
                name: "schools:create",
                path: "/schools/create",
                component: () => import(/* webpackChunkName: "page/school/edit" */ "./components/pages/school/SchoolEditPage.vue"),
                meta: {
                    icon: "mdi-plus",
                    title: "school.page.create.title",
                    parent: "schools",
                    permission: "urn:eszgsz:permission:school:create",
                },
            }, {
                name: "schools:edit",
                path: "/schools/:id(\\d+)",
                component: () => import(/* webpackChunkName: "page/meal/edit" */ "./components/pages/school/SchoolEditPage.vue"),
                props: (route) => {
                    const id = Number.parseInt(route.params.id, 10)
                    if (Number.isNaN(id)) {
                        return 0
                    }
                    return { id }
                },
                meta: {
                    icon: "mdi-pencil",
                    title: "school.page.edit.title",
                    parent: "schools",
                    permission: "urn:eszgsz:permission:school:edit",
                },
            }, {
                name: "employers",
                path: "/employers",
                component: () => import(/* webpackChunkName: "page/employer/list" */ "./components/pages/employer/EmployerListPage.vue"),
                meta: {
                    icon: "mdi-briefcase",
                    title: "employer.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:employer:list",
                },
            }, {
                name: "sideboard-kitchens",
                path: "/sideboard-kitchens",
                component: () => import(/* webpackChunkName: "page/sideboard-kitchen/list" */ "./components/pages/sideboard-kitchen/SideboardKitchenListPage.vue"),
                meta: {
                    icon: "mdi-chef-hat",
                    title: "sideboard-kitchen.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:sideboard-kitchen:list",
                },
            }, {
                name: "supply-items",
                path: "/supply-items",
                component: () => import(/* webpackChunkName: "page/supply-item/list" */ "./components/pages/supply-item/SupplyItemListPage.vue"),
                meta: {
                    icon: "mdi-file-cabinet",
                    title: "supply-item.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:supply-item:list",
                },
            }, {
                name: "activity-logs",
                path: "/activity-logs",
                component: () => import(/* webpackChunkName: "page/activity-log/list" */ "./components/pages/activity-log/ActivityLogListPage.vue"),
                meta: {
                    icon: "mdi-tray-full",
                    title: "activity-log.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:activity-log:list",
                },
            },

            {
                name: "workdays",
                path: "/workdays",
                component: () => import(/* webpackChunkName: "page/workday/edit" */ "./components/pages/workday/WorkdayEditPage.vue"),
                meta: {
                    icon: "mdi-calendar",
                    title: "workday.page.edit.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:workday:edit",
                },
            }, {
                name: "working-time-records",
                path: "/working-time-records",
                component: () => import(/* webpackChunkName: "page/working-time-record/list" */ "./components/pages/working-time-record/WorkingTimeRecordListPage.vue"),
                meta: {
                    icon: "mdi-timetable",
                    title: "working-time-record.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:working-time-record:list",
                },
            }, {
                name: "working-time-records:details",
                path: "/working-time-records/:id",
                component: () => import(/* webpackChunkName: "page/working-time-record/details" */ "./components/pages/working-time-record/WorkingTimeRecordDetailsPage.vue"),
                props: (route) => {
                    const id = Number.parseInt(route.params.id, 10)
                    if (Number.isNaN(id)) {
                        return 0
                    }
                    return { id }
                },
                meta: {
                    icon: "mdi-file-document-outline",
                    title: "working-time-record.page.details.breadcrumb",
                    parent: "working-time-records",
                    permission: "urn:eszgsz:permission:working-time-record:list",
                },
            }, {
                name: "food-orders:summary:office",
                path: "/food-orders/summary/office",
                component: () => import(/* webpackChunkName: "page/food-order/summary" */ "./components/pages/food-order/FoodOrderSummary.vue"),
                meta: {
                    icon: "mdi-food-variant",
                    title: "food-order.page.summary.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:food-order:summary:office",
                },
            }, {
                name: "food-orders:summary:supplier",
                path: "/food-orders/summary/supplier",
                component: () => import(/* webpackChunkName: "page/food-order/summary" */ "./components/pages/food-order/FoodOrderSummary.vue"),
                meta: {
                    icon: "mdi-food-variant",
                    title: "food-order.page.summary.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:food-order:summary:supplier",
                },
            }, {
                name: "food-orders:summary:school",
                path: "/food-orders/summary/school",
                component: () => import(/* webpackChunkName: "page/food-order/summary" */ "./components/pages/food-order/FoodOrderSummary.vue"),
                meta: {
                    icon: "mdi-food-variant",
                    title: "food-order.page.summary.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:food-order:summary:school",
                },
            }, {
                name: "food-orders:weekly:office",
                path: "/food-orders/weekly/office",
                component: () => import(/* webpackChunkName: "page/food-order/week" */ "./components/pages/food-order/FoodOrderWeekPage.vue"),
                meta: {
                    icon: "mdi-food-variant",
                    title: "food-order.page.weekly.breadcrumb",
                    parent: "home",
                    permission: "urn:eszgsz:permission:food-order:weekly:office",
                },
            },

            {
                name: "food-orders:weekly:school",
                path: "/food-orders/weekly/school",
                component: () => import(/* webpackChunkName: "page/food-order/week" */ "./components/pages/food-order/FoodOrderWeekPage.vue"),
                meta: {
                    icon: "mdi-food-variant",
                    title: "food-order.page.weekly.breadcrumb",
                    parent: "home",
                    permission: "urn:eszgsz:permission:food-order:weekly:school",
                },
            },

            {
                name: "food-orders:daily-summary:supplier",
                path: "food-orders/daily-summary/supplier",
                component: () => import(/* webpackChunkName: "page/food-order/daily-summary-for-supplier" */ "./components/pages/food-order/FoodOrderDailySummaryForSupplierPage.vue"),
                meta: {
                    icon: "mdi-food-variant",
                    title: "food-order.page.daily-summary.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:food-order:daily-summary:supplier",
                },
            },

            {
                name: "working-time-records:log",
                path: "working-time-records/log",
                component: () => import(/* webpackChunkName: "page/working-time-record/log" */ "./components/pages/working-time-record/WorkingTimeRecordLogPage.vue"),
                meta: {
                    icon: "mdi-timetable",
                    title: "working-time-record.page.log.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:working-time-record:log",
                },
            }, {
                name: "food-orders:daily-summary:sideboard-kitchen",
                path: "food-orders/daily-summary/sideboard-kitchen",
                component: () => import(/* webpackChunkName: "page/food-order/daily-summary-for-sideboard-kitchen" */ "./components/pages/food-order/FoodOrderDailySummaryForSideboardKitchenPage.vue"),
                meta: {
                    icon: "mdi-food-variant",
                    title: "food-order.page.daily-summary.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:food-order:daily-summary:sideboard-kitchen",
                },
            },

            {
                name: "documents",
                path: "/documents",
                component: () => import(/* webpackChunkName: "page/document/list" */ "./components/pages/document/DocumentListPage.vue"),
                meta: {
                    icon: "mdi-file-tree",
                    title: "document.page.list.title",
                    parent: "home",
                    permission: "urn:eszgsz:permission:document:list",
                },
            },

            {
                path: "/conversations",
                component: () => import(/* webpackChunkName: "page/conversation/list" */ "./components/pages/conversation/ConversationListPage.vue"),
                meta: {
                    icon: "mdi-message-text",
                    title: "conversation.page.list.title",
                    parent: "home",
                    permission: ["urn:eszgsz:permission:conversation:list", true],
                },
                children: [
                    {
                        name: "conversations",
                        path: "",
                        component: () => import(/* webpackChunkName: "page/conversation/message/empty" */ "./components/parts/conversation/ConversationMessageListEmpty.vue"),
                        meta: {
                            icon: "mdi-message-text",
                            title: "conversation.page.list.title",
                            parent: "home",
                            permission: ["urn:eszgsz:permission:conversation:list", true],
                        },
                    }, {
                        name: "conversations:create",
                        path: "create",
                        component: () => import(/* webpackChunkName: "page/conversation/create" */ "./components/parts/conversation/ConversationCreate.vue"),
                        meta: {
                            icon: "mdi-message-text",
                            title: "conversation.page.create.title",
                            parent: "conversations",
                            permission: ["urn:eszgsz:permission:conversation:list", true],
                        },
                    }, {
                        name: "conversations:show",
                        path: ":id(\\d+)",
                        component: () => import(/* webpackChunkName: "page/conversation/message/list" */ "./components/parts/conversation/ConversationMessageList.vue"),
                        props: (route) => {
                            const conversationId = Number.parseInt(route.params.id, 10)
                            if (Number.isNaN(conversationId)) {
                                return 0
                            }
                            return { conversationId }
                        },
                        meta: {
                            icon: "mdi-message-text",
                            title: "conversation.page.details.title",
                            parent: "conversations",
                            permission: ["urn:eszgsz:permission:conversation:show", true],
                        },
                    },
                ],
            }, {
                path: "/conversations/archived",
                component: () => import(/* webpackChunkName: "page/conversation/list" */ "./components/pages/conversation/ConversationListPage.vue"),
                props: () => {
                    return { archived: true }
                },
                meta: {
                    icon: "mdi-message-text",
                    title: "conversation.page.list-archived.title",
                    parent: "conversations",
                    permission: ["urn:eszgsz:permission:conversation:list:archived", true],
                },
                children: [
                    {
                        name: "conversations:archived",
                        path: "",
                        component: () => import(/* webpackChunkName: "page/conversation/message/empty" */ "./components/parts/conversation/ConversationMessageListEmpty.vue"),
                        meta: {
                            icon: "mdi-message-text",
                            title: "conversation.page.list-archived.title",
                            parent: "conversations",
                            permission: ["urn:eszgsz:permission:conversation:list:archived", true],
                        },
                    }, {
                        name: "conversations:archived:show",
                        path: ":id(\\d+)",
                        component: () => import(/* webpackChunkName: "page/conversation/message/list" */ "./components/parts/conversation/ConversationMessageList.vue"),
                        props: (route) => {
                            const conversationId = Number.parseInt(route.params.id, 10)
                            if (Number.isNaN(conversationId)) {
                                return 0
                            }
                            return { archived: true, conversationId }
                        },
                        meta: {
                            icon: "mdi-message-text",
                            title: "conversation.page.details.title",
                            parent: "conversations:archived",
                            permission: ["urn:eszgsz:permission:conversation:show:archived", true],
                        },
                    },
                ],
            },

            {
                name: "manual",
                path: "manual",
                component: () => import(/* webpackChunkName: "page/manual/toc" */ "./components/pages/manual/ManualTableOfContentPage.vue"),
                meta: {
                    icon: "mdi-notebook",
                    title: "manual.page.title",
                    parent: "home",
                },
            }, {
                name: "manual:changelog",
                path: "manual/changelog",
                component: () => import(/* webpackChunkName: "page/manual/changelog" */ "./components/pages/manual/changelog.md"),
                meta: {
                    icon: "mdi-notebook-outline",
                    title: "manual.page.changelog.title",
                    parent: "manual",
                },
            }, {
                name: "manual:introduction",
                path: "manual/introduction",
                component: () => import(/* webpackChunkName: "page/manual/introduction" */ "./components/pages/manual/introduction.md"),
                meta: {
                    icon: "mdi-notebook-outline",
                    title: "manual.page.introduction.title",
                    parent: "manual",
                },
            }, {
                name: "manual:users",
                path: "manual/users",
                component: () => import(/* webpackChunkName: "page/manual/users" */ "./components/pages/manual/users.md"),
                meta: {
                    icon: "mdi-notebook-outline",
                    title: "manual.page.users.title",
                    parent: "manual",
                },
            },

            {
                name: "not-found",
                path: "not-found",
                component: () => import(/* webpackChunkName: "page/not-found" */ "./components/pages/NotFoundPage.vue"),
                meta: {
                    icon: "mdi-alert-outline",
                    title: "layout.routes.error.notFound.title",
                    parent: "home",
                },
            },

            {
                path: "*",
                redirect: "not-found",
            },
        ],
    },
]

import { Module, Mutation, VuexModule } from "vuex-module-decorators"

@Module({ name: "snackbar", namespaced: true })
export class SnackbarModule extends VuexModule {
    public text: string = ""

    public color: string = "success"

    public visible: boolean = false

    @Mutation
    public openSnackbar({ text, color }: { text: string; color: string }): void {
        this.text = text
        this.color = color
        this.visible = true
    }

    @Mutation
    public closeSnackbar(): void {
        this.text = ""
        this.visible = false
    }
}

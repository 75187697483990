export const lang = {
    header: {
        title: "ÉSzGSz",
        subtitle: "Étkeztetési Szolgáltató<br/>Gazdasági Szervezet",
        "reaming-time": {
            tooltip: "Munkamenetből hátralévő idő",
        },
        "refresh-token-error": {
            tooltip: "Hiba történt a munkamenet frissítése közben.",
        },
        btn: {
            profile: {
                content: "Felhasználói adatok",
            },
            logout: {
                content: "Kilépés",
            },
        },
    },

    page: {
        home: {
            title: "Főoldal",
        },
    },

    btn: {
        conversation: {
            tooltip: "Üzenetek",
        },
        "role-switch": {
            tooltip: "Szerepkör váltás",
        },
    },

    dialog: {
        profile: {
            title: "Felhasználói fiók",
        },
        "role-switch": {
            title: "Szerepkör kiválasztása",
            "role-option": {
                "urn:eszgsz:role:admin": "Adminisztráció",
                "urn:eszgsz:role:office": "Iroda",
                "urn:eszgsz:role:labor-department": "Munkaügyi osztály",
                "urn:eszgsz:role:technical-department": "Műszaki osztály",
                "urn:eszgsz:role:school": "{name} iskola",
                "urn:eszgsz:role:supplier": "{name} beszállító",
                "urn:eszgsz:role:sideboard-kitchen": "Tálaló konyha",
            },
        },
    },

    snackbar: {
        "edit-profile": {
            success: "Adatok sikeresen elmentve.",
        },

        error: {
            loading: "Hiba a betöltés során.",
            validation: "A megadott adatok hibásak.",
            unauthorized: "Lejárt munakemenet. Átirányítás e belépő képernyőre.",
            forbidden: "Kérés megtagadva.",
            unknown: "Ismeretlen hiba történt a feldolgozás során.",
        },

        btn: {
            close: {
                content: "Bezárás",
            },
        },
    },
}

import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { Employer } from "../../../lib/eszgsz-api/model/Employer"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { CreateEmployerPayload } from "../../../lib/eszgsz-api/payload/employer/CreateEmployerPayload"
import { EditEmployerPayload } from "../../../lib/eszgsz-api/payload/employer/EditEmployerPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "employer", namespaced: true })
export class EmployerModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginated(payload?: PaginatedPayload): Promise<Pagination<Employer>> {
        return await Container.get(EszgszApi).paginateEmployers(payload)
    }

    @Action({ rawError: true })
    public async create(payload: CreateEmployerPayload): Promise<Employer> {
        return await Container.get(EszgszApi).createEmployer(payload)
    }

    @Action({ rawError: true })
    public async load({ id }: { id: number }): Promise<Employer> {
        return await Container.get(EszgszApi).loadEmployer(id)
    }

    @Action({ rawError: true })
    public async edit({ id, ...payload }: Pick<Employer, "id"> & EditEmployerPayload): Promise<Employer> {
        return await Container.get(EszgszApi).editEmployer(id, payload)
    }

    @Action({ rawError: true })
    public async remove({ id }: Pick<Employer, "id">): Promise<void> {
        await Container.get(EszgszApi).removeEmployer(id)
    }

    @Action({ rawError: true })
    public async searchByName({ name }: { name: string }): Promise<Array<Pick<Employer, "id" | "name">>> {
        return await Container.get(EszgszApi).searchEmployersByName(name)
    }

    @Action({ rawError: true })
    public async generateEmployerCode(): Promise<{ code: string }> {
        return await Container.get(EszgszApi).generateEmployerCode()
    }
}

import { Container } from "typedi"
import { Action, Module, VuexModule } from "vuex-module-decorators"
import { EszgszApi } from "../../../lib/eszgsz-api"
import { PersonWithSpecialNeeds } from "../../../lib/eszgsz-api/model/PersonWithSpecialNeeds"
import { Pagination } from "../../../lib/eszgsz-api/Pagination"
import { DailyForSupplier } from "../../../lib/eszgsz-api/payload/person-with-special-needs/DailyForSupplier"
import { SearchPeopleWithSpecialNeedsPayload } from "../../../lib/eszgsz-api/payload/person-with-special-needs/SearchPeopleWithSpecialNeedsPayload"
import { PaginatedPayload } from "../../contract/PaginatedPayload"

@Module({ name: "person-with-special-needs", namespaced: true })
export class PersonWithSpecialNeedsModule extends VuexModule {
    @Action({ rawError: true })
    public async loadPaginatedForSupplier(
        payload: PaginatedPayload<DailyForSupplier>,
    ): Promise<Pagination<{ name: string; need: string; meal_name: string; menu_name: string; school_name: string }>> {
        return await Container.get(EszgszApi).loadPaginatedPeopleWithSpecialNeedsForSupplier(payload)
    }

    @Action({ rawError: true })
    public async searchByName(
        payload: SearchPeopleWithSpecialNeedsPayload,
    ): Promise<Array<Pick<PersonWithSpecialNeeds, "id" | "name">>> {
        return await Container.get(EszgszApi).searchPeopleWithSpecialNeedsByName(payload)
    }
}

export const lang = {
    name: "Étkezés | Étkezések",

    model: {
        name: "Név",
    },

    navigation: {
        list: "Étkezések",
    },

    btn: {
        create: {
            tooltip: "Új étkezés",
        },
    },

    page: {
        list: {
            title: "Étkezések kezelése",
        },
    },

    dialog: {
        create: {
            title: "Új étkezés létrehozása",
        },
        edit: {
            title: "Étkezés szerkesztése",
        },
        remove: {
            title: "Étkezés törlése",
            content: "Biztosan törli a kiválasztott étkezést? Minden az étkezéshez kapcsolt bejegyzés törlésre kerül.",
        },
    },

    snackbar: {
        create: {
            success: "Az új étkezés sikeresen létrehozva.",
        },
        edit: {
            success: "Étkezés sikeresen szerkesztve.",
        },
        remove: {
            success: "Étkezés sikeresen törölve.",
        },
    },
}

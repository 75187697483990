import * as qs from "qs"
import { getModule } from "vuex-module-decorators"
import { store } from "../../src"
import { PaginatedPayload } from "../../src/contract/PaginatedPayload"
import { AuthModule } from "../../src/store/modules/AuthModule"
import { ForbiddenException } from "../http-exception/ForbiddenException"
import { UnauthorizedException } from "../http-exception/UnauthorizedException"
import { UnknownHttpException } from "../http-exception/UnknownHttpException"
import { UnprocessableEntityException } from "../http-exception/UnprocessableEntityException"
import { EszgszApi } from "./EszgszApi"
import { Conversation } from "./model/Conversation"
import { ConversationMessage } from "./model/ConversationMessage"
import { Document } from "./model/Document"
import { Employer } from "./model/Employer"
import { Log } from "./model/Log"
import { Meal } from "./model/Meal"
import { Menu } from "./model/Menu"
import { PersonWithSpecialNeeds } from "./model/PersonWithSpecialNeeds"
import { School } from "./model/School"
import { SideboardKitchen } from "./model/SideboardKitchen"
import { Supplier } from "./model/Supplier"
import { SupplyItem } from "./model/SupplyItem"
import { User } from "./model/User"
import { WeekOrder } from "./model/WeekOrder"
import { Workday } from "./model/Workday"
import { WorkingTimeRecord } from "./model/WorkingTimeRecord"
import { Pagination } from "./Pagination"
import { CreateConversationPayload } from "./payload/conversation/CreateConversationPayload"
import { CreateMealPayload } from "./payload/CreateMealPayload"
import { CreateMenuPayload } from "./payload/CreateMenuPayload"
import { CreateSupplierPayload } from "./payload/CreateSupplierPayload"
import { CreateUserPayload } from "./payload/CreateUserPayload"
import { DocumentUploadPayload } from "./payload/document/DocumentUploadPayload"
import { EditMealPayload } from "./payload/EditMealPayload"
import { EditMenuPayload } from "./payload/EditMenuPayload"
import { EditSupplierPayload } from "./payload/EditSupplierPayload"
import { EditUserPayload } from "./payload/EditUserPayload"
import { EditUserProfilePayload } from "./payload/EditUserProfilePayload"
import { CreateEmployerPayload } from "./payload/employer/CreateEmployerPayload"
import { EditEmployerPayload } from "./payload/employer/EditEmployerPayload"
import { EditWeekOrderPayload } from "./payload/food-orders/EditWeekOrderPayload"
import { SummaryForOfficePayload } from "./payload/food-orders/SummaryForOfficePayload"
import { SummaryForSchoolPayload } from "./payload/food-orders/SummaryForSchoolPayload"
import { SummaryForSupplierPayload } from "./payload/food-orders/SummaryForSupplierPayload"
import { WeekOrderPayload } from "./payload/food-orders/WeekOrderPayload"
import { LoginPayload } from "./payload/LoginPayload"
import { DailyForSupplier } from "./payload/person-with-special-needs/DailyForSupplier"
import { SearchPeopleWithSpecialNeedsPayload } from "./payload/person-with-special-needs/SearchPeopleWithSpecialNeedsPayload"
import { CreateSchoolPayload } from "./payload/school/CreateSchoolPayload"
import { EditSchoolPayload } from "./payload/school/EditSchoolPayload"
import { CreateSideboardKitchenPayload } from "./payload/sideboard-kitchen/CreateSideboardKitchenPayload"
import { EditSideboardKitchenPayload } from "./payload/sideboard-kitchen/EditSideboardKitchenPayload"
import { CreateSupplyItemPayload } from "./payload/supply-item/CreateSupplyItemPayload"
import { EditSupplyItemPayload } from "./payload/supply-item/EditSupplyItemPayload"
import { EditWorkdaysPayload } from "./payload/workdays/EditWorkdaysPayload"
import { ListWorkdaysPayload } from "./payload/workdays/ListWorkdaysPayload"
import { CreateWorkingTimeRecordPayload } from "./payload/working-time-record/CreateWorkingTimeRecordPayload"
import { EditWorkingTimeRecordPayload } from "./payload/working-time-record/EditWorkingTimeRecordPayload"

export class RemoteEszgszApi implements EszgszApi {
    protected tokenRefreshInProgress: Promise<unknown> | null = null

    public async login(
        payload: LoginPayload,
    ): Promise<{ jwtToken: string; roles: Array<{ name: string; permissions: string[]; meta?: { [key: string]: unknown } }>; permissions: string[] }> {
        return await this.fetch("POST", "/login", payload, { skipToken: true, skipTokenRefresh: true })
    }

    public async refreshToken(): Promise<{ jwtToken: string; roles: Array<{ name: string; permissions: string[]; meta?: { [key: string]: unknown } }>; permissions: string[] }> {
        return await this.fetch("POST", "/refresh_token", {}, { skipTokenRefresh: true })
    }

    public async paginateUsers(payload?: PaginatedPayload): Promise<Pagination<User>> {
        return await this.fetch("GET", "/users", payload)
    }

    public async createUser(payload: CreateUserPayload): Promise<User> {
        return await this.fetch("POST", "/users", payload)
    }

    public async editUser(id: number, payload: EditUserPayload): Promise<User> {
        return await this.fetch("POST", `/users/${id}`, payload)
    }

    public async editUserProfile(payload: EditUserProfilePayload): Promise<User> {
        return await this.fetch("POST", `/profile`, payload)
    }

    public async removeUser(id: number): Promise<void> {
        return await this.fetch("DELETE", `/users/${id}`)
    }

    public async searchUsersByName(name: string): Promise<Array<Pick<User, "id" | "name">>> {
        return await this.fetch("GET", "/users/search", { name })
    }

    public async paginatedMenus(payload?: PaginatedPayload): Promise<Pagination<Menu>> {
        return await this.fetch("GET", "/menus", payload)
    }

    public async createMenu(payload: CreateMenuPayload): Promise<Menu> {
        return await this.fetch("POST", "/menus", payload)
    }

    public async editMenu(id: number, payload: EditMenuPayload): Promise<Menu> {
        return await this.fetch("POST", `/menus/${id}`, payload)
    }

    public async removeMenu(id: number): Promise<void> {
        return await this.fetch("DELETE", `/menus/${id}`)
    }

    public async searchMenusByName(
        name: string,
        options?: { special?: boolean },
    ): Promise<Array<Pick<Menu, "id" | "name">>> {
        if (!!options && options.special) {
            return await this.fetch("GET", "/menus/search", { name, special: true })
        }

        return await this.fetch("GET", "/menus/search", { name })
    }

    public async paginateMeals(payload?: PaginatedPayload): Promise<Pagination<Meal>> {
        return await this.fetch("GET", "/meals", payload)
    }

    public async createMeal(payload: CreateMealPayload): Promise<Meal> {
        return await this.fetch("POST", "/meals", payload)
    }

    public async editMeal(id: number, payload: EditMealPayload): Promise<Meal> {
        return await this.fetch("POST", `/meals/${id}`, payload)
    }

    public async removeMeal(id: number): Promise<void> {
        return await this.fetch("DELETE", `/meals/${id}`)
    }

    public async searchMealsByName(name: string): Promise<Array<Pick<Meal, "id" | "name">>> {
        return await this.fetch("GET", "/meals/search", { name })
    }

    public async paginateSuppliers(payload?: PaginatedPayload): Promise<Pagination<Supplier>> {
        return await this.fetch("GET", "/suppliers", payload)
    }

    public async createSupplier(payload: CreateSupplierPayload): Promise<Supplier> {
        return await this.fetch("POST", "/suppliers", payload)
    }

    public async editSupplier(id: number, payload: EditSupplierPayload): Promise<Supplier> {
        return await this.fetch("POST", `/suppliers/${id}`, payload)
    }

    public async removeSupplier(id: number): Promise<void> {
        return await this.fetch("DELETE", `/suppliers/${id}`)
    }

    public async searchSuppliersByName(name: string): Promise<Array<Pick<Supplier, "id" | "name">>> {
        return await this.fetch("GET", "/suppliers/search", { name })
    }

    public async paginateSchools(payload?: PaginatedPayload): Promise<Pagination<School>> {
        return await this.fetch("GET", `/schools`, payload)
    }

    public async createSchool(payload: CreateSchoolPayload): Promise<School> {
        return await this.fetch("POST", "/schools", payload)
    }

    public async loadSchool(id: number): Promise<School> {
        return await this.fetch("GET", `/schools/${id}`)
    }

    public async editSchool(id: number, payload: EditSchoolPayload): Promise<School> {
        return await this.fetch("POST", `/schools/${id}`, payload)
    }

    public async removeSchool(id: number): Promise<void> {
        return await this.fetch("DELETE", `/schools/${id}`)
    }

    public async searchSchoolsByName(
        name: string,
        options: { betweenCustomWorkdays?: boolean } = {},
    ): Promise<Array<Pick<School, "id" | "name">>> {
        return await this.fetch("GET", "/schools/search", { name, ...options })
    }

    public async paginateEmployers(payload?: PaginatedPayload): Promise<Pagination<Employer>> {
        return await this.fetch("GET", `/employers`, payload)
    }

    public async createEmployer(payload: CreateEmployerPayload): Promise<Employer> {
        return await this.fetch("POST", "/employers", payload)
    }

    public async loadEmployer(id: number): Promise<Employer> {
        return await this.fetch("GET", `/employers/${id}`)
    }

    public async editEmployer(id: number, payload: EditEmployerPayload): Promise<Employer> {
        return await this.fetch("POST", `/employers/${id}`, payload)
    }

    public async removeEmployer(id: number): Promise<void> {
        return await this.fetch("DELETE", `/employers/${id}`)
    }

    public async searchEmployersByName(name: string): Promise<Array<Pick<Employer, "id" | "name">>> {
        return await this.fetch("GET", `/employers/search`, { name })
    }

    public async generateEmployerCode(): Promise<{ code: string }> {
        return await this.fetch("GET", `/employers/generate-code`)
    }

    public async paginateSideboardKitchens(payload?: PaginatedPayload): Promise<Pagination<SideboardKitchen>> {
        return await this.fetch("GET", `/sideboard-kitchens`, payload)
    }

    public async createSideboardKitchen(payload: CreateSideboardKitchenPayload): Promise<SideboardKitchen> {
        return await this.fetch("POST", "/sideboard-kitchens", payload)
    }

    public async editSideboardKitchen(
        id: number,
        payload: EditSideboardKitchenPayload,
    ): Promise<SideboardKitchen> {
        return await this.fetch("POST", `/sideboard-kitchens/${id}`, payload)
    }

    public async removeSideboardKitchen(id: number): Promise<void> {
        return await this.fetch("DELETE", `/sideboard-kitchens/${id}`)
    }

    public async paginateSupplyItems(payload?: PaginatedPayload): Promise<Pagination<SupplyItem>> {
        return await this.fetch("GET", `/supply-items`, payload)
    }

    public async createSupplyItem(payload: CreateSupplyItemPayload): Promise<SupplyItem> {
        return await this.fetch("POST", "/supply-items", payload)
    }

    public async editSupplyItem(id: number, payload: EditSupplyItemPayload): Promise<SupplyItem> {
        return await this.fetch("POST", `/supply-items/${id}`, payload)
    }

    public async removeSupplyItem(id: number): Promise<void> {
        return await this.fetch("DELETE", `/supply-items/${id}`)
    }

    public async searchSupplyItemByName(name: string): Promise<Array<Pick<SupplyItem, "id" | "name" | "packing">>> {
        return await this.fetch("GET", `/supply-items/search`, { name })
    }

    public async paginateActivityLogs(payload?: PaginatedPayload): Promise<Pagination<Log>> {
        return await this.fetch("GET", `/activity-logs`, payload)
    }

    public async paginateDocuments(payload?: PaginatedPayload): Promise<Pagination<Document>> {
        return await this.fetch("GET", `/documents`, payload)
    }

    public async uploadDocument(payload: DocumentUploadPayload): Promise<Document> {
        const formData = new FormData()

        formData.append("file", payload.file)

        return await this.fetch("POST", "/documents", formData, { multipart: true })
    }

    public async downloadDocument(id: number): Promise<{ filename: string; content: Blob }> {
        return await this.fetch("GET", `/documents/${id}`, {}, { download: true })
    }

    public async removeDocument(id: number): Promise<void> {
        return await this.fetch("DELETE", `/documents/${id}`)
    }

    public async loadWorkdays(payload: ListWorkdaysPayload): Promise<Workday[]> {
        return await this.fetch("GET", "/workdays", payload)
    }

    public async editWorkdays(payload: EditWorkdaysPayload): Promise<Workday[]> {
        return await this.fetch("POST", "/workdays", payload)
    }

    public async loadPaginatedWorkingTimeRecords(
        payload?: PaginatedPayload,
    ): Promise<Pagination<Pick<Employer, "id" | "name"> & { correct_days: number; problematic_days: number; holiday_count: number }>> {
        return await this.fetch("GET", "/working-time-records", payload)
    }

    public async loadPaginatedWorkingTimeRecordsForEmployer(
        employerId: number,
        payload?: PaginatedPayload,
    ): Promise<Pagination<WorkingTimeRecord>> {
        return await this.fetch("GET", `/working-time-records/employers/${employerId}`, payload)
    }

    public async createWorkingTimeRecordForEmployer(
        employerId: number,
        payload: CreateWorkingTimeRecordPayload,
    ): Promise<WorkingTimeRecord> {
        return await this.fetch("POST", `/working-time-records/employers/${employerId}/details`, payload)
    }

    public async editWorkingTimeRecordForEmployer(
        employerId: number,
        id: number,
        payload: EditWorkingTimeRecordPayload,
    ): Promise<WorkingTimeRecord> {
        return await this.fetch("POST", `/working-time-records/employers/${employerId}/details/${id}`, payload)
    }

    public async removeWorkingTimeRecord(employerId: number, id: number): Promise<void> {
        return await this.fetch("DELETE", `/working-time-records/employers/${employerId}/details/${id}`)
    }

    public async loadPaginatedFoodOrdersSummaryForOffice(payload: PaginatedPayload<SummaryForOfficePayload>): Promise<Pagination<unknown>> {
        return await this.fetch("GET", `/food-orders/summary/office`, payload)
    }

    public async loadPaginatedFoodOrdersSummaryForSchool(payload: PaginatedPayload<SummaryForSchoolPayload>): Promise<Pagination<unknown>> {
        return await this.fetch("GET", `/food-orders/summary/school`, payload)
    }

    public async loadPaginatedFoodOrdersSummaryForSupplier(payload: PaginatedPayload<SummaryForSupplierPayload>): Promise<Pagination<unknown>> {
        return await this.fetch("GET", `/food-orders/summary/supplier`, payload)
    }

    public async loadFoodOrdersWeekly(
        payload: WeekOrderPayload,
    ): Promise<{ days: Array<"monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday">; orders: WeekOrder[] }> {
        return await this.fetch("GET", `/food-orders/weekly`, payload)
    }

    public async editFoodOrdersWeekly(
        payload: EditWeekOrderPayload,
    ): Promise<{ days: Array<"monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday">; orders: WeekOrder[] }> {
        return await this.fetch("POST", `/food-orders/weekly`, payload)
    }

    public async loadPaginatedPeopleWithSpecialNeedsForSupplier(
        payload: PaginatedPayload<DailyForSupplier>,
    ): Promise<Pagination<{ name: string; need: string; meal_name: string; menu_name: string; school_name: string }>> {
        return await this.fetch("GET", `/persons-with-special-needs/supplier`, payload)
    }

    public async searchPeopleWithSpecialNeedsByName(
        payload: SearchPeopleWithSpecialNeedsPayload,
    ): Promise<Array<Pick<PersonWithSpecialNeeds, "id" | "name">>> {
        return await this.fetch("GET", `/persons-with-special-needs/search`, payload)
    }

    public async loadPaginatedFoodOrdersDailySummaryForSupplier(
        payload: PaginatedPayload,
    ): Promise<Pagination<unknown> & { schools: School[] }> {
        return await this.fetch("GET", `/food-orders/daily-summary/supplier`, payload)
    }

    public async logStartWorkingTimeRecord(payload: { code: string }): Promise<void> {
        return await this.fetch("POST", `/working-time-records/log-start`, payload)
    }

    public async logFinishWorkingTimeRecord(payload: { code: string }): Promise<void> {
        return await this.fetch("POST", `/working-time-records/log-finish`, payload)
    }

    public async loadFoodOrdersDailySummaryForSideboardKitchen(
        payload: PaginatedPayload,
    ): Promise<Pagination<unknown> & { personsWithSpecialNeeds: PersonWithSpecialNeeds[] }> {
        return await this.fetch("GET", `/food-orders/daily-summary/sideboard-kitchen`, payload)
    }

    public async getUnreadConversationCount(): Promise<{ unreadConversationCount: number }> {
        return await this.fetch("GET", `/conversations/unread`, {}, { skipTokenRefresh: true })
    }

    public async loadPaginatedConversations(
        payload?: PaginatedPayload,
    ): Promise<Pagination<Pick<Conversation, "id" | "type" | "subject"> & { lastMessageDate: string; unreadMessageCount: number }>> {
        return await this.fetch("GET", `/conversations`, payload)
    }

    public async loadPaginatedArchivedConversations(
        payload?: PaginatedPayload,
    ): Promise<Pagination<Pick<Conversation, "id" | "type" | "subject"> & { lastMessageDate: string; unreadMessageCount: number }>> {
        return await this.fetch("GET", `/conversations/archived`, payload)
    }

    public async createConversation(
        payload: CreateConversationPayload,
    ): Promise<Partial<Pick<Conversation, "messages">> & Omit<Conversation, "messages">> {
        return await this.fetch("POST", `/conversations`, payload)
    }

    public async loadConversation(id: number): Promise<Conversation & { _meta: { permissions: string[] } }> {
        return await this.fetch("GET", `/conversations/${id}`)
    }

    public async loadArchivedConversation(id: number): Promise<Conversation & { _meta: { permissions: string[] } }> {
        return await this.fetch("GET", `/conversations/archived/${id}`)
    }

    public async replyToConversation(id: number, payload: { content: string }): Promise<ConversationMessage> {
        return await this.fetch("POST", `/conversations/${id}/reply`, payload)
    }

    public async archiveConversation(id: number): Promise<void> {
        return await this.fetch("POST", `/conversations/${id}/archive`)
    }

    public async loadConversationMessages(id: number): Promise<ConversationMessage[]> {
        return await this.fetch("GET", `/conversations/${id}/messages`)
    }

    public async loadArchivedConversationMessages(id: number): Promise<ConversationMessage[]> {
        return await this.fetch("GET", `/conversations/archived/${id}/messages`)
    }

    public async searchRecipientsByName(
        payload: { name: string },
    ): Promise<Array<{ id: number; name: string; type: "supplier" | "school" | "sideboard-kitchen" | "user" }>> {
        return await this.fetch("GET", `/conversations/recipients/search`, payload)
    }

    protected async fetch<T>(
        method: string,
        url: string,
        body?: unknown,
        options: { download?: boolean; multipart?: boolean; skipToken?: boolean; skipTokenRefresh?: boolean } = {},
    ): Promise<T> {
        options = {
            download: false,
            multipart: false,
            skipToken: false,
            skipTokenRefresh: false,
            ...options,
        }

        method = method.toUpperCase()

        const headers: Record<string, string> = {
            "Accept": "application/json",
        }

        if (body !== undefined && !options.multipart) {
            headers["Content-Type"] = "application/json"
        }

        const token = getModule(AuthModule, store).jwtToken

        if (token && !options.skipToken) {
            headers["Authorization"] = `Bearer ${token}`
        }

        if (method === "GET" && typeof body === "object") {
            const query = qs.stringify(body)

            if (query) {
                url = `${url}?${query}`
            }
        }

        const response = await fetch(`/api${url}`, {
            method,
            headers,
            body: method !== "GET" && body
                ? (body instanceof FormData ? body : JSON.stringify(body))
                : undefined,
        })

        if (response.status >= 200 && response.status < 300) {
            if (!options.skipTokenRefresh) {
                await this.initializeTokenRefresh()
            }

            return options.download
                ? {
                    filename: this.getFileNameFromContentDispositionHeader(response.headers.get("content-disposition")),
                    content: await response.blob(),
                }
                : response.json()
        }

        switch (response.status) {
            case 401:
                throw new UnauthorizedException()

            case 403:
                throw new ForbiddenException()

            case 422:
                throw new UnprocessableEntityException((await response.json()).messages)

            default:
                throw new UnknownHttpException()
        }
    }

    protected getFileNameFromContentDispositionHeader(header: string | null): string {
        if (header === null) {
            return ""
        }

        const contentDisposition = header.split(";")
        const fileNameToken = `filename*=UTF-8''`

        let fileName = "downloaded.pdf"
        for (const value of contentDisposition) {
            if (value.trim().indexOf(fileNameToken) === 0) {
                fileName = decodeURIComponent(value.trim().replace(fileNameToken, ""))
                break
            }
        }

        return fileName
    }

    protected async initializeTokenRefresh(): Promise<void> {
        const token = getModule(AuthModule, store).jwtToken

        // Try to refresh token before any api call
        if (token) {
            try {
                if (this.tokenRefreshInProgress === null) {
                    this.tokenRefreshInProgress = getModule(AuthModule, store).refresh()
                }

                await this.tokenRefreshInProgress
            } catch {
                // Silently catch the error without and try to send the main request
            } finally {
                this.tokenRefreshInProgress = null
            }
        }
    }
}

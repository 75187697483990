import fileSize from "filesize"
import moment from "moment-timezone"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import Vue from "vue"
import VueI18n from "vue-i18n"
import VueMoment from "vue-moment"
import { Component } from "vue-property-decorator"
import VueRouter from "vue-router"
import VueScroll from "vuescroll"
import Vuetify from "vuetify"
import Vuex from "vuex"
import ManualPage from "./components/layout/ManualPage.vue"
import { router } from "./core/router"
import { messages } from "./lang"
import { createStore } from "./store"

moment.locale("hu")
moment.defaultFormat = "YYYY.MM.DD. HH:mm:ss"

Vue.use(VueRouter)
Vue.use(VueI18n)
Vue.use(Vuetify)
Vue.use(Vuex)
Vue.use(VueScroll)
Vue.use(VueMoment, {
    moment,
})

Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ManualPage", ManualPage)

Vue.filter("filesize", (value: number) => fileSize(value))

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteUpdate",
])

export const i18n = new VueI18n({
    locale: "hu",
    messages,
})

const vuetify = new Vuetify({
    iconfont: "mdi",
    lang: {
        current: "hu",
        t: (key: string, ...params: Array<string | number>): string => i18n.t(key, params) as string,
    },
    theme: {
        themes: {
            light: {
                primary: "#3F51B5",
                secondary: "#2196F3",
            },
            dark: {
                primary: "#3F51B5",
                secondary: "#2196F3",
            },
        },
    },
})

export const store = createStore()

;(async function() {
    const App = (await import(/* webpackChunkName: "layout/app" */ "./components/App.vue")).default

    // tslint:disable-next-line:no-unused-expression
    new Vue({
        el: "#app",
        router,
        i18n,
        vuetify,
        store,
        render: (h) => h(App),
    })
})()


import lodashSet from "lodash.set"

const langFileNamesContext = require.context(".", true, /\.lang\..+/)

export const messages = {}

// Parse all language files
langFileNamesContext.keys().forEach((key) => {
    const { lang } = langFileNamesContext(key)

    lodashSet(messages, key.replace(/\.\/(.+)\.lang\..+/, "$1").replace(/\//g, "."), lang)
})
